<template>
  <div class="dialog talk-dialog">
    <span class="pseudo">{{ pseudo }}</span>
    <div class="dialog-content talk-dialog-content" v-html="sentence" />

    <button
      @click="onButtonClick"
      type="button" name="next" class="btn-dialog"
    >
      Space
    </button>
  </div>
</template>

<script>
/* global me */
import { createPopper } from '@popperjs/core'


export default {
  name: 'ChatDialog',

  props: {
    pseudo: { type: String, required: true },
    content: { type: [Array, String], required: true },
    pos: { type: Object, required: true }
  },

  data () {
    return {
      sentence: typeof this.content === 'string' ? this.content : this.content[0],
      index: 0
    }
  },

  methods: {
    getPos () {
      const x = this.pos.x + (5 * me.video.scaleRatio.x)
      const y = this.pos.y - (20 * me.video.scaleRatio.x)
      return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x
      })
    },

    onKeydown (e) {
      e.stopPropagation()
      if (e.code === 'Space') {
        this.onButtonClick()
      }
    },

    onButtonClick () {
      if (typeof this.content !== 'string' && this.content.length - 1 > this.index) {
        this.sentence = this.content[++this.index]
      } else {
        this.$emit('finished')
      }
    }
  },

  mounted () {
    const virtualElement = { getBoundingClientRect: this.getPos() }
    createPopper(virtualElement, this.$el, { placement: 'top-start' })
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.talk-dialog {
  max-width: 350px;
  min-width: 200px;
  border-bottom-left-radius: 0;
}
</style>
