/* global me */

import { ressources } from '@/game/ressources'
import {
  PlayerEntity,
  FriendEntity,
  MailboxEntity,
  ShrineEntity,
  BikeEntity,
  PosterEntity
} from '@/game/entities'
import { PearIcon } from '@/game/misc'


export default {
  state: {
  },

  mutations: {
  },

  actions: {
    'INIT_GAME' ({ state, dispatch }) {
      return new Promise(resolve => {
        me.device.onReady(() => {
          const w = 600
          const h = window.innerHeight / 4
          if (!me.video.init(w, h, { parent: 'screen', scaleMethod: 'fill-max', doubleBuffering: true, renderer: me.video.AUTO })) {
            alert('Your browser does not support HTML5 canvas.')
          }
          me.loader.preload(ressources, () => {
            resolve(dispatch('ON_GAME_LOADED'))
          })
        })
      })
    },

    'ON_GAME_LOADED' ({ dispatch }) {
      return new Promise(resolve => {
        me.pool.register('PlayerEntity', PlayerEntity)
        me.pool.register('FriendEntity', FriendEntity)
        me.pool.register('MailboxEntity', MailboxEntity)
        me.pool.register('ShrineEntity', ShrineEntity)
        me.pool.register('BikeEntity', BikeEntity)
        me.pool.register('PosterEntity', PosterEntity)
        me.pool.register('PearIcon', PearIcon)

        me.levelDirector.loadLevel('forest', {
          container: me.game.world,
          onLoaded: () => resolve(dispatch('ON_LEVEL_LOADED'))
        })
      })
    },

    'ON_LEVEL_LOADED' ({ commit }) {
      commit('SET_STATE', ['ready', true])
    }
  },

  getters: {
  }
}
