import Vue from 'vue'
import Vuex from 'vuex'

import api from './api'
import game from './game'
Vue.use(Vuex)


export default new Vuex.Store({
  ...api,

  modules: {
    game
  }
})
