<template>
  <div class="mission-dialog">
    <div class="mission-dialog-wrapper">
      <div class="mission-dialog-mission">
        {{ status === 'successful' ? 'Mission terminée !' : 'Mission !'}}
      </div>
      <div class="dialog-content mission-dialog-content">
        <template v-if="status === 'successful'">
          <h4 class="mission-dialog-title">
            <template v-if="step === 'pear'">
              Bravo ! Tu as gagné la poire <span class="big cap">{{ pear }}</span>
            </template>
            <template v-else-if="step === 'gps'">
              Tu crois voir des chiffres sur la poire : <span class="big">{{ gpsNumber }}</span>

            </template>
            <template v-else-if="step === 'bonus'">
              Tu as aussi gagné une poire <span class="big">Bonus</span>
            </template>
          </h4>
          <div class="pear-anim" v-if="['pear', 'bonus'].includes(step)">
            <div class="star star-1" />
            <div class="star star-2" />
            <div class="star star-3" />
            <div class="icon-pear" :class="[bonusIndex > -1 ? 'green' : 'yellow']" />
          </div>
        </template>
        <template v-else>
          <h4 class="mission-dialog-title" v-html="title" />
        </template>
        <div v-if="content" v-html="content" />

        <button
          type="button" name="accept"
          class="btn-dialog"
          @click="$emit('finished')"
        >
          Accepter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* global me */
import { createPopper } from '@popperjs/core'


export default {
  name: 'MissionDialog',

  props: {
    title: { type: String, required: true },
    status: { type: String, required: true },
    pear: { type: String, default: undefined },
    available: { type: Object, default: undefined },
    successful: { type: Object, default: undefined },
    bonus: { type: Array, default: undefined },
    gps: { type: Boolean, default: undefined }
  },

  data () {
    return {
      content: this[this.status].content,
      bonusIndex: -1,
      step: 'pear',
      gpsNumber: this.gps ? this.$store.getters.nextGpsNumber : null
    }
  },

  methods: {
    onKeydown (e) {
      e.stopPropagation()
      if (e.code === 'Space') {
        if (this.status === 'successful') {
          if (this.step === 'pear' && this.gps) {
            this.step = 'gps'
            this.content = 'Qu\'est-ce que ça pourrait bien signifier ?'
          } else if (this.bonus && this.bonus.length - 1 > this.bonusIndex) {
            this.step = 'bonus'
            this.content = this.successful.bonus[this.bonus[++this.bonusIndex]]
          } else {
            this.$emit('finished')
          }
        } else {
          this.$emit('finished')
        }
      }
    }
  },

  mounted () {
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.mission-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // height: 100vh;
  // width: 100vw;
  display: flex;

  &-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-height: calc(100vh - 100px);
    max-width: 900px;
  }

  padding: 20px;

  &-content {
    background-color: $color-2;
    padding: 50px 90px;
    border-radius: 90px;
    border-top-left-radius: 0;
    color: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &-mission {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-self: flex-start;

    background-color: $color-2;
    color: $white;
  }

  &-title {
    padding: 0 8px 0 0;
    margin: 0;
    margin-bottom: 2rem;

    font-weight: 700;
    text-align: center;
    color: $white;

    ::v-deep {
      .big {
        display: block;
        line-height: 1;
        font-size: 3rem;
        text-shadow: 8px 8px $color-1;
      }
    }
  }

  .pear-anim {
    display: flex;
    justify-content: center;
    width: 41 * 10px;
    height: 41 * 10px;
    position: relative;
    top: -25px;

    @keyframes rotate { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }
    @keyframes rotate2 { from { transform: rotate(0deg); } to { transform: rotate(-360deg); }  }

    .star {
      position: absolute;
      background-size: cover;
      image-rendering: crisp-edges;
      width: 100%;
      height: 100%;
      animation: 10s linear 0s infinite rotate;
    }

    .star-1 {
      background-image: url('~@/assets/imgs/animpear1.png');
      animation-duration: 2s;
    }
    .star-2 {
      background-image: url('~@/assets/imgs/animpear2.png');
      animation-duration: 3s;
      animation-name: rotate2;
    }
    .star-3 {
      background-image: url('~@/assets/imgs/animpear3.png');
    }

    @keyframes rotatepear { from { transform: rotate(-5deg); } to { transform: rotate(5deg); } }

    .icon-pear {
      position: absolute;
      display: inline-block;
      width: 16 * 10px;
      height: 16 * 10px;
      top: 10 * 10px;
      background-size: cover;
      image-rendering: crisp-edges;
      animation: 1s linear 0s infinite alternate rotatepear;

      &.yellow {
        background-image: url('~@/assets/imgs/pear-yellow.png');
      }

      &.green {
        background-image: url('~@/assets/imgs/pear-green.png');
      }
    }
  }
}
</style>
