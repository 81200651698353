<template>
  <div class="loading-screen full-abs">
    <div class="loading-screen-ready full-abs" :class="{ fade: start }">
      <div class="loading-screen-title" />

      <button
        type="button" name="accept"
        class="btn-dialog"
        @click="onButtonClick"
      >
        Connecter
      </button>
    </div>

    <div class="loading-screen-waiting full-abs" :class="{ fade: ready }">
      <div class="loading-screen-bg" />

      <div class="loading-screen-content">
        <h2>CHANGEMENT DE DIMENSION<br> CATÉGORIE 7</h2>
        <p>Merci de patienter…</p>
      </div>

      <span class="loading-screen-note">/!\ GLITCH NON IDENTIFIÉ DÉTÉCTÉ /!\</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',

  props: {
    ready: { type: Boolean, required: true }
  },

  data () {
    return {
      start: false
    }
  },

  methods: {
    onKeydown (e) {
      e.stopPropagation()
      if (['Space', 'Enter'].includes(e.code)) {
        this.onButtonClick()
      }
    },

    onButtonClick () {
      this.start = true
      document.removeEventListener('keydown', this.onKeydown)
    }
  },

  mounted () {
    document.addEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .fade {
    opacity: 0;
  }

  &-waiting {
    background-color: black;
    padding: 10% 5%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s;


  }

  .loading-screen-content {
    position: relative;
    color: $white;
    background-color: $color-2;
    border-radius: 90px;
    padding: 90px;

    h2 {
      font-weight: 700;
      animation: 2s linear 0s infinite shadow;
    }
  }

  .loading-screen-note {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: $color-2;
    background-color: $white;
    padding: 0 .5rem;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-image: url('~@/assets/imgs/galaxy.png');
    background-size: contain;
    image-rendering: crisp-edges;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    animation: 350s linear 0s infinite move;
  }

  @keyframes move { from { transform: rotate(0deg); } to { transform: rotate(360deg); }  }

  $color: darken($color-2, 30%);

  @keyframes shadow {
    0% { text-shadow: -6px -6px $color; }
    25% { text-shadow: -12px 3px $color; }
    25% { text-shadow: 6px -15px $color; }
    25% { text-shadow: -6px 12px $color; }
    100% { text-shadow: 10px -6px $color; }
  }


  &-ready {
    padding: 10% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s;
  }

  &-title {
    background-image: url('~@/assets/imgs/title.png');
    background-size: contain;
    image-rendering: crisp-edges;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  .btn-dialog {
    pointer-events: auto;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
}
</style>
