<template>
  <div class="multi-inputs">
    <div v-for="(word, i) in words" :key="i" class="multi-inputs-word">
      <input
        v-for="(char, j) in word" :key="j"
        v-model="words[i][j]"
        @input="onInput($event, i, j)"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiInputs',

  props: {
    format: { type: String, required: true }
  },

  data () {
    return {
      words: this.format.split(' ').map(word => word.split('').map(char => ''))
    }
  },

  methods: {
    onInput (e, i, j) {
      this.words[i][j] = e.data ? e.data.toUpperCase() : ''
      this.$emit('input', this.words.map(word => word.join('')).join(' '))
      this.focusNextInput(e.target)
    },

    focusNextInput (item) {
      if (item.nextElementSibling) {
        item.nextElementSibling.focus()
      } else if (item.parentElement.nextElementSibling) {
        item.parentElement.nextElementSibling.firstChild.focus()
      } else {
        item.blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-inputs {
  display: flex;
}

.multi-inputs-word:not(:last-child) {
  margin-right: 1rem
}

input {
  width: 1rem;
  height: 1.5rem;
  text-align: center;
  font: inherit;
}
</style>
