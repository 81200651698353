<template>
  <div class="dialog-full dialog-poster">
    <div class="dialog-full-wrapper">
      <div v-if="content" v-html="content" />
      <img :src="img" @click="onClick">
    </div>
  </div>
</template>

<script>
/* global me */


export default {
  name: 'PosterDialog',

  props: {
    title: { type: String },
    content: { type: String },
    img: { type: String }
  },

  data () {
    return {
    }
  },

  methods: {
    onKeydown (e) {
      e.stopPropagation()
      if (e.code === 'Space') {
        this.$emit('finished')
      }
    },

    onClick () {
      window.open('/' + this.img)
    }
  },

  mounted () {
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.dialog-poster {
  .dialog-full-wrapper {
    max-height: 100vh;
    flex-direction: row;
    justify-content: center;
  }

  img {
    max-height: 100vh;
    padding: 10px;
    cursor: pointer;
    background-color: #8e3d21;
    border-radius: 10px;
    border: 5px solid #641d34;
    margin: 10px;
    image-rendering: crisp-edges;
  }
}
</style>
