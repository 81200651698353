<template>
  <div class="draw-debug" :style="`--x: ${pos.x}px; --y: ${pos.y}px;`" />
</template>

<script>
export default {
  name: 'Debug',

  props: {
    pos: { type: Object, default: null }
  }
}
</script>

<style lang="scss" scoped>
.draw-debug {
  position: absolute;
  top: var(--y);
  left: var(--x);
  width: #{16px * 5};
  height: #{16px * 5};
  opacity: .5;
  background-color: red;
  transform: translate(-50%, -50%);
}
</style>
