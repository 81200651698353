/* global me */
import store from '@/store'


export const icons = {
  talk: [0],
  mission: [1]
}

export const Icon = me.Sprite.extend({
  init (pos, anchorPoint, animKeys, onRemove) {
    this._super(me.Sprite, 'init', [pos.x, pos.y, {
      image: me.loader.getImage('icons'),
      framewidth: 16,
      frameheight: 16
    }])

    this.addAnimation('icon', animKeys)
    this.setCurrentAnimation('icon')
    this.onRemove = onRemove || function () {}
    this.anchorPoint.set(anchorPoint.x, anchorPoint.y + 1)

    this.tween = new me.Tween(this.pos).to({ y: this.pos.y - 5 }, 300)
    this.tween.yoyo(true).repeat(Infinity).start()

    me.game.world.addChild(this)
  },

  remove () {
    this.tween.stop()
    me.game.world.removeChild(this)
    this.onRemove()
  }
})


export const MissionIcon = Icon.extend({
  init (pos, anchorPoint, onRemove) {
    this._super(Icon, 'init', [pos, anchorPoint, icons.mission, onRemove])
    this.pos.z += 3
  }
})


export const InteractIcon = Icon.extend({
  init (pos, anchorPoint, playerPos, iconName = 'talk', onRemove, distance = 20) {
    this._super(Icon, 'init', [pos, anchorPoint, icons[iconName], onRemove])
    this.basePos = pos
    this.playerPos = playerPos
    this.distance = distance
  },

  update (dt) {
    if (this.basePos.distance(this.playerPos) > this.distance) {
      this.remove()
    }
    return this._super(Icon, 'update', [dt])
  }
})


export const PearIcon = me.Sprite.extend({
  init (x, y, settings) {
    this._super(me.Sprite, 'init', [x, y, {
      image: me.loader.getImage('icons'),
      framewidth: 16,
      frameheight: 16
    }])
    this.name = 'PearIcon'
    this.pear = settings.pear
    if (settings.pear.includes('bonus')) {
      this.alpha = store.getters.bonus.includes(settings.pear) ? 1 : 0
      this.addAnimation('icon', [3])
    } else {
      this.alpha = store.getters.givenPears.includes(settings.pear) ? 1 : 0
      this.addAnimation('icon', [2])
    }

    this.setCurrentAnimation('icon')
  }
})
