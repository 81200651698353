<template>
  <header class="game-header" v-if="ready">
    <div class="game-header-item">
      <div class="icon-pear green" />
      <span class="score">{{ score.bonus }}</span>
    </div>

    <div class="game-header-item">
      <div class="icon-pear yellow" />
        <span class="score">{{ score.pears }}</span>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GameHeader',

  computed: {
    ...mapGetters(['ready', 'score'])
  }
}
</script>

<style lang="scss" scoped>
.game-header {
  $size: 16 * 5px;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  height: $size + 20px;
  display: flex;
  justify-content: flex-end;

  &-item {
    display: flex;
    align-items: center;
    width: $size * 2;

    .score {
      position: relative;
      display: inline-block;
      font-weight: 700;
      font-size: 50px;
      transform: rotate(-deg);
      color: $white;
      text-shadow: 6px 6px $color-2;
    }
  }

  .icon-pear {
    display: inline-block;
    width: $size;
    height: $size;
    background-size: cover;
    image-rendering: crisp-edges;

    &.yellow {
      background-image: url('~@/assets/imgs/pear-yellow.png');
    }

    &.green {
      background-image: url('~@/assets/imgs/pear-green.png');
    }
  }

}
</style>
