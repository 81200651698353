/* global me */
import EventBus from '@/game/EventBus'
import store from '@/store'
import { InteractEntity } from './BaseEntities'
import { MissionIcon, TalkIcon } from '@/game/misc'


export default InteractEntity.extend({
  init (x, y, settings) {
    this._super(InteractEntity, 'init', [x, y, settings])

    this.missionIcon = null

    const info = store.getters.friendData('poster')
    this.humanName = info.name
    this.seen = store.getters.eventDone('poster')

    if (!this.seen) {
      setTimeout(() => {
        this.missionIcon = new MissionIcon(this.pos, this.anchorPoint)
      }, 100)
    }
  },

  interact () {
    EventBus.$once('dialog-end', this.onPosterEnd.bind(this))
    EventBus.$emit('dialog-start', {
      pseudo: this.humanName,
      type: 'poster-dialog',
      img: 'data/poster.png'
    })
  },

  onPosterEnd () {
    if (!this.seen) {
      this.missionIcon.remove()
      this.missionIcon = null
      store.dispatch('SEEN_ITEM', { name: 'poster' })
    }
  }
})
