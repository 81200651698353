/* global me */
import EventBus from '@/game/EventBus'
import store from '@/store'
import { InteractIcon } from '@/game/misc'
import FriendEntity from './FriendEntity'


export default FriendEntity.extend({
  init (x, y, settings) {
    settings.pseudo = 'bike'
    settings.framewidth = 48
    settings.frameheight = 32
    settings.standFrame = 0
    settings.aX = 0
    settings.aY = -0.5
    this._super(FriendEntity, 'init', [x, y, settings])

    this.name = 'BikeEntity'
    const info = store.getters.friendData(this.pseudo)
    this.travelChoices = info.choices
    this.ask = info.ask
    if (!store.getters.eventDone('bike')) {
      this.hide()
    }
  },

  hide () {
    this.body.collisionType = me.collision.types.NO_OBJECT
    this.renderable.alpha = 0
  },

  show () {
    this.body.collisionType = me.collision.types.NPC_OBJECT
    this.renderable.alpha = 1
  },

  addIcon (playerPos) {
    if (this.interactIcon) return
    const pos = new me.Vector2d().copy(this.pos)
    pos.add(new me.Vector2d(48 / 3, 0))
    this.interactIcon = new InteractIcon(pos, this.anchorPoint, playerPos, this.iconName, () => {
      this.interactIcon = null
    }, 48)
  },

  onCollision (response, other) {
    if (this.body.collisionType === me.collision.types.NPC_OBJECT && other.body.collisionType === me.collision.types.PLAYER_OBJECT) {
      this.addIcon(other.pos)
    }
    return false
  },

  interact () {
    const currentLevel = me.levelDirector.getCurrentLevel().name
    const action = {
      pseudo: this.humanName,
      pos: this.getScreenPos(),

      type: 'choose',
      ask: this.ask,
      choices: this.travelChoices.filter(choice => {
        return choice[0] !== currentLevel
      })
    }

    EventBus.$once('ask-end', this.onAskEnd.bind(this))
    EventBus.$emit('ask-start', action)
  },

  onAskEnd (choice) {
    const currentLevel = me.levelDirector.getCurrentLevel().name
    const map = this.travelChoices.filter(choice => {
      return choice[0] !== currentLevel
    })[choice][0]
    me.game.viewport.fadeIn('#561afd', 500)
    setTimeout(() => {
      me.levelDirector.loadLevel(map, {
        onLoaded: () => {
          me.game.viewport.fadeOut('#561afd', 500)
        }
      })
    }, 500)
  }
})
