/* global me */
import EventBus from '@/game/EventBus'


export default me.Entity.extend({
  init (x, y, settings) {
    this._super(me.Entity, 'init', [x + 8, y + 8, settings])
    this.anchorPoint.set(-0.5, -0.5)
    console.log(this.anchorPoint)
  },

  getScreenPos () {
    return me.game.viewport.worldToLocal(this.pos.x, this.pos.y).scale(me.video.scaleRatio.x)
  },

  update (dt) {
    this._super(me.Entity, 'update', [dt])
    EventBus.$emit('draw', [this.pseudo, this.getScreenPos()])
    return true
  },

  talk (player) {
    console.log('MailboxEntity')
    EventBus.$emit('mailbox-opened', {
      type: 'mailbox',
    })
  }
})
