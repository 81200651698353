import 'melonjs/dist/melonjs'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false

// Register global components
const requireComponent = require.context('@/components', true, /\.vue$/i)
// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component
  console.log(fileName)
  const component = requireComponent(fileName).default
  console.log(component.name)
  // Globally register the component
  Vue.component(component.name, component)
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
