/* global me */
import { BaseEntity } from './BaseEntities'


export default BaseEntity.extend({
  init (x, y, settings) {
    settings.sprite = true
    settings.aY = -1
    this._super(BaseEntity, 'init', [x, y, settings])

    this.velx = 70
    this.maxX = me.game.viewport.width - this.width
    this.dir = 'down'
    this.body.collisionType = me.collision.types.PLAYER_OBJECT

    me.input.bindKey(me.input.KEY.Q, 'left')
    me.input.bindKey(me.input.KEY.D, 'right')
    me.input.bindKey(me.input.KEY.Z, 'up')
    me.input.bindKey(me.input.KEY.S, 'down')

    this.renderable.addAnimation('stand-down', [3])
    this.renderable.addAnimation('stand-right', [10])
    this.renderable.addAnimation('stand-up', [19])
    this.renderable.addAnimation('stand-left', [26])
    this.renderable.addAnimation('walk-down', [0, 1, 2, 3, 4, 5, 6, 7], 100)
    this.renderable.addAnimation('walk-right', [8, 9, 10, 11, 12, 13], 128)
    this.renderable.addAnimation('walk-up', [16, 17, 18, 19, 20, 21, 22, 23], 100)
    this.renderable.addAnimation('walk-left', [24, 25, 26, 27, 28, 29], 128)

    this.renderable.setCurrentAnimation('stand-' + this.dir)

    // set the viewport to follow this renderable on both axis, and enable damping
    me.game.viewport.follow(this.pos, me.game.viewport.AXIS.BOTH, 1)
  },

  update (dt) {
    const dirs = ['left', 'right', 'up', 'down'].filter(dir => me.input.isKeyPressed(dir))

    if (dirs.length) {
      this.dir = dirs[0]
      if (!this.renderable.isCurrentAnimation('walk-' + this.dir)) {
        this.renderable.setCurrentAnimation('walk-' + this.dir)
      }
      for (const dir of dirs) {
        const move = this.velx * dt / 1000
        const d = ['left', 'right'].includes(dir) ? 'x' : 'y'
        const m = ['left', 'up'].includes(dir) ? -1 : 1
        this.pos[d] += move * m
      }

      me.collision.check(this)

      this._super(BaseEntity, 'update', [dt])
      return true
    } else {
      if (!this.renderable.isCurrentAnimation('stand-' + this.dir)) {
        this.renderable.setCurrentAnimation('stand-' + this.dir)
      }
      return false
    }
  },

  onCollision (response, other) {
    switch (other.body.collisionType) {
      case me.collision.types.WORLD_SHAPE:
        break

      case me.collision.types.NPC_OBJECT:
        break

      default:
        // Do not respond to other objects
        return false
    }

    // Make the object solid
    return true
  }
})
