const api = {
  options: {
    headers: {
      'Content-Type': 'application/json'
    },
  },

  fetch (uri, options) {
    return fetch(uri, options).then(resp => {
      console.log(`fetched ${uri}`, resp)
      if (resp.status !== 200) {
        throw new Error(resp.statusText)
      }
      return resp.json()
    })
  },

  get (uri) {
    return this.fetch('/api' + uri, this.options)
  },

  put (uri, data) {
    return this.fetch('/api' + uri, {
      method: 'PUT',
      ...this.options,
      body: JSON.stringify(data)
    })
  }
}


export default {
  state: {
    connected: false,
    ready: false,

    acceptedMissions: undefined,
    successfulMissions: undefined,
    bonusMissions: undefined,
    finishedMissions: undefined,
    doneChats: undefined,
    readMails: undefined,
    events: undefined,
    givenPears: undefined,

    pears: undefined,
    bonus: undefined,
    gps: undefined,

    mails: undefined,
  },

  mutations: {
    'SET_API_DATA' (state, data) {
      console.log('SET_API_DATA', data)
      for (const key in data) {
        state[key] = data[key]
      }
    },

    'APPEND_STATE' (state, [key, value]) {
      if (state[key].includes(value)) return
      state[key].push(value)
    },

    'CONCAT_STATE' (state, [key, values]) {
      for (var value of values) {
        if (!state[key].includes(value)) {
          state[key].push(value)
        }
      }
    },

    'SET_STATE' (state, [key, value]) {
      state[key] = value
    }
  },

  actions: {
    'UPDATE_API_STATE' ({ commit }, { key, value, type = 'append' }) {
      console.log('UPDATE_API_STATE', key, value, type)
      return api.put('/state', { type, key, value }).then(() => {
        if (type === 'append') {
          commit('APPEND_STATE', [key, value])
        } else if (type === 'set') {
          commit('SET_STATE', [key, value])
        } else if (type === 'concat') {
          commit('CONCAT_STATE', [key, value])
        }
        return name
      })
    },

    'LOGIN' ({ commit, dispatch }) {
      return api.get('/login').then(async ({ connected }) => {
        await dispatch('GET_API_DATA')
        commit('SET_STATE', ['connected', true])
      }).catch(err => {
        console.log('ERR', err)
        commit('SET_STATE', ['connected', false])
      })
    },

    'GET_API_DATA' ({ state, commit }) {
      return api.get('/data').then(data => {
        commit('SET_API_DATA', data)
        return data
      })
    },

    'MISSION_ACCEPTED' ({ dispatch }, { name }) {
      return dispatch('UPDATE_API_STATE', { key: 'acceptedMissions', value: name })
    },

    async 'MISSION_SUCCESSFUL' ({ dispatch }, { name, bonus }) {
      if (bonus) {
        await dispatch('UPDATE_API_STATE', { key: 'bonusMissions', value: bonus, type: 'concat' })
      }
      dispatch('UPDATE_API_STATE', { key: 'successfulMissions', value: name })
    },

    async 'MISSION_FINISHED' ({ dispatch }, { name, pear, bonus, gps }) {
      if (bonus) {
        await dispatch('UPDATE_API_STATE', { key: 'bonus', value: bonus, type: 'concat' })
      }
      if (pear) {
        await dispatch('UPDATE_API_STATE', { key: 'pears', value: pear })
      }
      if (gps) {
        await dispatch('UPDATE_API_STATE', { key: 'gps', value: gps })
      }
      return dispatch('UPDATE_API_STATE', { key: 'finishedMissions', value: name })
    },

    'UNIQUE_CHAT_DONE' ({ dispatch }, { name }) {
      return dispatch('UPDATE_API_STATE', { key: 'doneChats', value: name })
    },

    'EVENT_DONE' ({ dispatch }, { name }) {
      return dispatch('UPDATE_API_STATE', { key: 'events', value: name })
    },

    'PEARS_GIVEN' ({ dispatch }, { pears }) {
      return dispatch('UPDATE_API_STATE', { key: 'givenPears', value: pears, type: 'concat' })
    },

    'MAIL_READ' ({ dispatch }, { name }) {
      return dispatch('UPDATE_API_STATE', { key: 'readMails', value: name })
    }
  },

  getters: {
    connected: state => state.connected,
    ready: state => state.ready,

    acceptedMissions: state => state.acceptedMissions,
    successfulMissions: state => state.successfulMissions,
    finishedMissions: state => state.finishedMissions,
    doneChats: state => state.doneChats,
    readMails: state => state.readMails,
    pears: state => state.pears,
    givenPears: state => state.givenPears,
    bonus: state => state.bonus,

    mails: state => state.mails,

    score: state => {
      return {
        pears: state.pears.length,
        bonus: state.bonus.length
      }
    },

    nextGpsNumber: state => {
      return state.gpsNumbers.find(number => !state.gps.includes(number))
    },

    // param getters
    friendData: state => pseudo => {
      const data = { ...state.data[pseudo] }
      if ('mission' in data) {
        const name = data.mission.name
        if (state.finishedMissions.includes(name)) {
          delete data.mission
        } else if (state.successfulMissions.includes(name)) {
          data.mission.status = 'successful'
          const bonus = state.bonusMissions.filter(bonus => bonus.includes(name))
          if (bonus.length) {
            data.mission.bonus = bonus
          }
        } else if (!state.acceptedMissions.includes(data.mission.name)) {
          data.mission.status = 'available'
        } else {
          data.mission.status = 'inprogress'
        }
      }
      if ('uniqueChats' in data) {
        const uniqueChats = data.uniqueChats.filter(chat => !state.doneChats.includes(chat.name))
        if (uniqueChats.length) {
          data.uniqueChat = uniqueChats[0]
        }
      }
      return data
    },

    uniqueChat: state => pseudo => {
      const data = state.data[pseudo]
      if (!data || !data.uniqueChats) return
      for (const chat of data.uniqueChats) {
        if (!state.doneChats.includes(chat.name)) {
          return chat
        }
      }
    },

    eventDone: state => event => {
      return state.events.includes(event)
    },

    givablePears: state => {
      return state.pears.filter(pear => !state.givenPears.includes(pear))
    }
  }
}
