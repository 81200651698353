/* global me */
import EventBus from '@/game/EventBus'
import store from '@/store'
import { InteractEntity } from './BaseEntities'
import { MissionIcon, TalkIcon } from '@/game/misc'


export default InteractEntity.extend({
  init (x, y, settings) {
    if (settings.sprite === undefined) {
      settings.sprite = true
    }
    this._super(InteractEntity, 'init', [x, y, settings])
    console.log(this.pseudo, this)

    this.missionIcon = null

    const info = store.getters.friendData(this.pseudo)
    this.humanName = info.name
    this.chatChoices = info.chat
    this.chatIndex = 0
    this.mission = info.mission
    this.uniqueChat = info.uniqueChat

    if ((this.mission && this.mission.status !== 'inprogress') || this.uniqueChat) {
      setTimeout(() => {
        this.missionIcon = new MissionIcon(this.pos, this.anchorPoint)
      }, 100)
    }
  },

  interact () {
    const action = {
      pseudo: this.humanName,
      pos: this.getScreenPos(),

      type: 'chat',
      content: this.chatChoices[this.chatIndex]
    }

    if (this.uniqueChat) {
      action.content = this.uniqueChat.content
      EventBus.$once('talk-end', this.onUniqueChatEnd.bind(this))
    } else if (this.mission && this.mission.status !== 'inprogress') {
      action.type = 'mission'
      action.mission = this.mission
      action.content = this.mission[this.mission.status].intro
      EventBus.$once('mission-end', this.onMissionEnd.bind(this))
    } else {
      EventBus.$once('talk-end', this.onChatEnd.bind(this))
    }

    EventBus.$emit('talk-start', action)
  },

  onChatEnd () {
    const nextI = this.chatIndex + 1
    this.chatIndex = nextI > this.chatChoices.length - 1 ? 0 : nextI
  },

  async onUniqueChatEnd () {
    await store.dispatch('UNIQUE_CHAT_DONE', { name: this.uniqueChat.name })
    this.uniqueChat = store.getters.uniqueChat(this.pseudo)
    if (!this.mission && !this.uniqueChat && this.missionIcon) {
      this.missionIcon.remove()
      this.missionIcon = null
    }
  },

  onMissionEnd () {
    const status = this.mission.status
    if (status === 'available') {
      store.dispatch('MISSION_ACCEPTED', { name: this.mission.name })
    } else if (status === 'done') {
      store.dispatch('MISSION_SUCCESSFUL', {
        name: this.mission.name,
        bonus: this.mission.bonus
      })
      this.mission.status = 'successful'
    } else if (status === 'successful') {
      store.dispatch('MISSION_FINISHED', {
        name: this.mission.name,
        pear: this.mission.pear,
        bonus: this.mission.bonus,
        gps: this.mission.gps ? store.getters.nextGpsNumber : undefined
      })
    }

    if (status !== 'done') {
      this.missionIcon.remove()
      this.missionIcon = null
      this.mission = null
    }
  }
})
