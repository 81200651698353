<template>
  <div class="dialog dialog-full dialog-purple dialog-special rebu">
    <div class="dialog-wrapper">
      <div class="dialog-tab">
        Mission !
      </div>
      <div class="dialog-content">
        <h4 class="rebu-title" v-html="title" />

        <p>Pour cette mission, tu devras réaliser la recette suivante. Bon courage !</p>

        <div
          class="part"
        >
          <h3>Première partie</h3>

          <p>Dans un premier temps, il faudra que tu retrouves les ingrédients de la recette grâce aux rébus suivants.</p>

          <div
            v-for="(line, i) in rebu" :key="i"
            class="rebu-line" :class="{ false: rebuValidation[i] === false }"
          >
            <span class="rebu-number">{{ i + 1 }}</span>
            <div class="rebu-imgs">
              <template v-for="elem in line.rebu">
                <img v-if="elem.length > 1" :src="'/data/missions/gourmande/' + elem" :key="elem + i">
                <span v-else :key="elem + i">{{ elem }}</span>
              </template>
            </div>

            <div class="rebu-quantity">
              {{ line.quantity }}
            </div>

            <multi-inputs :format="line.result" v-model="rebuResults[i]" />
          </div>

          <div class="wrong" v-if="rebuOk === false" ref="wrong">
            Déso mais toutes les réponses ne sont pas bonnes !
          </div>

          <button
            type="button" name="accept"
            class="btn-dialog"
            @click="checkRebuResults"
          >
            Vérifier
          </button>
        </div>

        <div
          class="part"
        >
          <h3>Deuxième partie</h3>

          <p>Une fois que tu auras trouvé les ingrédients, tu devras compléter la recette ci-dessous. Je te propose pour cela un petit exercice de traduction 😉 Tu devras évidemment rajouter les ingrédients que tu viens de trouver en te référant aux numéros.</p>

          <ul>
            <li v-for="(line, i) in trous" :key="i" class="trou-line">
              <template v-for="(elem, j) in line">
                <span :key="elem + i + j">{{ elem }}</span>
                <input
                  v-if="j < line.length - 1" :key="elem + i"
                  class="trou-input" :id="i + '' + j" :name="i + '' + j"
                  v-model="trousResults[i][j]"
                  :class="{ false: trousValidation[i][j] === false }"
                >
              </template>
            </li>
          </ul>


          <div class="wrong" v-if="trousOk === false" ref="wrong">
            Déso mais toutes les réponses ne sont pas bonnes !
          </div>

          <button
            type="button" name="accept"
            class="btn-dialog"
            @click="checkTrouResults"
          >
            Vérifier
          </button>
        </div>

        <div class="wrong" v-if="everyOk === false" ref="wrong">
          Déso mais toutes les réponses ne sont pas bonnes !
        </div>

        <button
          type="button" name="accept"
          class="btn-dialog"
          @click="checkEverything"
        >
          C'est bon !
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionGourmande',

  props: {
    title: { type: String, required: true },
    data: { type: Object, required: true }
  },

  data () {
    const rebu = this.data.rebu
    const trous = this.data.trous.map(line => line.split('___'))
    return {
      rebu,
      trous,
      rebuAnswer: this.data.rebu.map(line => line.result),
      trousAnswer: this.data.trousAnswer,
      rebuOk: undefined,
      trousOk: undefined,
      everyOk: undefined,
      rebuResults: Array(rebu.length).fill(''),
      trousResults: trous.map(trouGroup => Array(trouGroup.length - 1).fill('')),
      rebuValidation: Array(rebu.length),
      trousValidation: trous.map(trouGroup => Array(trouGroup.length - 1))
    }
  },

  methods: {
    checkRebuResults () {
      this.rebuValidation = this.rebuResults.map((result, i) => result === this.rebuAnswer[i])
      this.rebuOk = this.rebuValidation.every(valid => valid)
    },

    checkTrouResults () {
      this.trousValidation = this.trousResults.map((trouGroup, i) => {
        return trouGroup.map((trou, j) => trou === this.trousAnswer[i][j])
      })
      this.trousOk = this.trousValidation.every(trouGroup => trouGroup.every(bool => bool))
    },

    checkEverything () {
      this.checkRebuResults()
      this.checkTrouResults()
      this.everyOk = this.rebuOk && this.trousOk
      if (this.everyOk) {
        this.$emit('finished')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rebu {
  .dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      margin-bottom: .5rem;
      color: $color-1;
      font-size: 2rem;
    }
  }

  &-title {
    padding: 0 8px 0 0;
    margin: 0;
    margin-bottom: 2rem;
    align-self: center;


    font-weight: 700;
    text-align: center;
    color: $white;

    ::v-deep {
      .big {
        display: block;
        line-height: 1;
        font-size: 3rem;
        text-shadow: 8px 8px $color-1;
      }
    }
  }

  .wrong {
    margin-top: 2rem;
    background-color: $color-1;
    padding: .5rem .75rem;
    border-radius: 50rem;
    color: $dark;
  }
  .part {
    width: 100%;
  }
  .rebu-line {
    max-height: 150px;
    display: flex;
    padding: 1rem;
    margin-bottom: 1rem;
    box-sizing: content-box;
    align-items: flex-end;

    .rebu-number {
      position: relative;
      left: -2rem;
      align-self: center;
      font-size: 2rem;
    }

    .rebu-imgs {
      height: 100%;
      flex-shrink: 2;
      display: flex;
      align-items: flex-end;

      > * {
        margin-right: 1rem;
      }
      img {
        height: 150px;
        display: block;
      }

      font-size: 6rem;
    }

    .rebu-quantity {
      margin-left: auto;
      // margin-right: auto;
      margin-right: 1rem;
    }

    &.false {
      border: 2px dashed $color-1;
      border-radius: 5px;
    }
  }

  .trou-line {
    line-height: 2rem;
    margin-bottom: 1rem;

    input {
      font: inherit;
      height: 1.5rem;

      &.false {
        border: 3px solid $color-1;
      }
    }
  }
}
</style>
