<template>
  <div class="mission-dialog">
    <div class="mission-dialog-wrapper">
      <div class="mission-dialog-mission">
        Quizz !
      </div>
      <div class="dialog-content mission-dialog-content">
        <h4 class="mission-dialog-title" v-html="title" />

        <div
          v-for="(q, i) in questions" :key="i"
          class="question"
          :class="{ 'false': ok !== undefined && validation[i] === false }"
        >
          <h4>{{ q.question }}</h4>

          <div v-for="(choice, j) in q.choices" :key="i + '' + j">
            <input
              type="radio" :id="i + '' + j" :name="i"
              :value="j" v-model="results[i]"
            >
            <label :for="i + '' + j">{{ choice }}</label>
          </div>
        </div>

        <div class="wrong" v-if="ok === false" ref="wrong">
          Déso mais toutes les réponses ne sont pas bonnes !
        </div>

        <button
          type="button" name="accept"
          class="btn-dialog"
          @click="checkResults"
        >
          Vérifier
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizzDialog',

  props: {
    title: { type: String, required: true },
    questions: { type: Array, required: true }
  },

  data () {
    return {
      ok: undefined,
      results: this.questions.map(() => (undefined)),
      validation: this.questions.map(() => (undefined))
    }
  },

  methods: {
    checkResults () {
      this.validation = this.results.map((result, i) => result === this.questions[i].answer)
      this.ok = this.validation.every(valid => valid)
      if (this.ok) {
        this.$emit('finished')
      }
    }
  },

  created () {
  },

  mounted () {
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.mission-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;

  &-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 90vh;
    width: 90%;
  }

  padding: 20px;

  &-content {
    background-color: $color-2;
    padding: 50px 90px;
    border-radius: 90px;
    border-top-left-radius: 0;
    color: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 100%;
    overflow-y: auto;

    h4 {
      margin-bottom: .5rem;
    }
  }

  &-mission {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-self: flex-start;

    background-color: $color-2;
    color: $white;
  }

  &-title {
    padding: 0 8px 0 0;
    margin: 0;
    margin-bottom: 2rem;
    align-self: center;


    font-weight: 700;
    text-align: center;
    color: $white;

    ::v-deep {
      .big {
        display: block;
        line-height: 1;
        font-size: 3rem;
        text-shadow: 8px 8px $color-1;
      }
    }
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 20px;
    height: 20px;

    border: 4px solid $color-1;
    transition: 0.2s all linear;

    position: relative;
    top: 2px;
    margin-right: .5rem;

    &:checked {
      background-color: $color-1;
    }
  }

  .wrong {
    margin-top: 2rem;
    background-color: $color-1;
    padding: .5rem .75rem;
    border-radius: 50rem;
    color: $dark;
  }

  .question {
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;

    h4 {
      margin-top: 0;
    }

    &.false {
      border: 2px dashed $color-1;
      border-radius: 5px;
    }
  }
}
</style>
