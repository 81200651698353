<template>
  <div id="app">
    <main clas="wh-100">
      <loading-screen v-if="connected" :ready="ready" />
      <router-view v-if="connected" />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'App',

  computed: {
    ...mapGetters(['connected', 'ready'])
  },

  created () {
    this.$store.dispatch('LOGIN')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>

<style lang="scss" scoped>
main {
}
</style>
