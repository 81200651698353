/* global me */
import EventBus from '@/game/EventBus'
import { MissionIcon, InteractIcon } from '@/game/misc'


export const BaseEntity = me.Entity.extend({
  init (x, y, settings) {
    this.pseudo = settings.pseudo
    if (settings.sprite) {
      settings = {
        image: me.loader.getImage(this.pseudo),
        framewidth: settings.framewidth || 16,
        frameheight: settings.frameheight || 32,
        ...settings
      }
    }

    this._super(me.Entity, 'init', [x, y, settings])

    if (settings.sprite) {
      this.renderable.addAnimation('stand', [settings.standFrame !== undefined ? settings.standFrame : 3])
      this.renderable.setCurrentAnimation('stand')
    }

    this.alwaysUpdate = true

    this.anchorPoint.set(
      settings.aX !== undefined ? settings.aX : 0,
      settings.aY !== undefined ? settings.aY : -0.25
    )
  },

  getScreenPos () {
    const x = this.pos.x
    const y = this.pos.y - this.height * this.anchorPoint.y * 2
    return me.game.viewport.worldToLocal(x, y).scale(me.video.scaleRatio.x, me.video.scale.y)
  }
})


export const InteractEntity = BaseEntity.extend({
  init (x, y, settings) {
    this._super(BaseEntity, 'init', [x, y, settings])
    this.pseudo = settings.pseudo
    this.iconName = settings.iconName || 'talk'
    this.interactIcon = null

    this.body.collisionType = me.collision.types.NPC_OBJECT

    me.event.subscribe(me.event.KEYDOWN, this.onKeydown.bind(this))
  },

  onCollision (response, other) {
    if (other.body.collisionType === me.collision.types.PLAYER_OBJECT) {
      this.addIcon(other.pos)
    }
    return false
  },

  onKeydown (action, key) {
    if (key === me.input.KEY.SPACE && this.interactIcon) {
      this.interact()
    }
  },

  addIcon (playerPos) {
    if (this.interactIcon) return
    this.interactIcon = new InteractIcon(this.pos, this.anchorPoint, playerPos, this.iconName, () => {
      this.interactIcon = null
    })
  },

  interact () {
    console.log('interact !')
  }
})
