<template>
  <div class="dialog dialog-full dialog-purple dialog-special twine">
    <div class="dialog-wrapper">
      <div class="dialog-tab">
        Mission !
      </div>

      <div class="dialog-content">
        <video
          v-if="currentPlace.videos && currentPlace.videos.length" controls
          :src="`/data/missions/twine/${place}/${currentPlace.videos[videoIndex]}`" autoplay
          @ended="onVideoEnded"
        >
        </video>

        <div v-html="currentPlace.text" />
        <ul v-if="currentPlace.choices && Object.keys(currentPlace.choices).length">
          <li
            v-for="(text, choice) in currentPlace.choices"
            :key="choice"
            @click="travel(choice)"
          >
            {{ text }}
          </li>
        </ul>
        <ul v-else-if="!isLastPlace">
          <li @click="travel(data.start)">
            Retourner au point de départ
          </li>
        </ul>
        <ul v-else>
          <li @click="travel(data.start)">
            Recommencer depuis le début
          </li>
          <li @click="$emit('finished')">
            Terminer
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionTwine',

  props: {
    data: { type: Object, required: true }
  },

  data () {
    return {
      history: [],
      place: this.data.start,
      videoIndex: 0
    }
  },

  computed: {
    currentPlace () {
      return this.data.places[this.place]
    },

    isLastPlace () {
      return this.place === this.data.end
    }
  },

  methods: {
    travel (place) {
      if (place === 'undo') {
        this.history.pop()
        this.place = this.history[this.history.length - 1]
      } else {
        this.history.push(place)
        this.place = place
      }
      this.videoIndex = 0
    },

    onVideoEnded () {
      if (this.currentPlace.videos[this.videoIndex + 1]) {
        this.videoIndex += 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.twine {
  ul {
    padding: 0;
    li {
      text-decoration: underline;
      list-style: square;
      cursor: pointer;
    }
  }

  video {
    min-height: 60vh;
    margin-bottom: 1rem;
  }

  .btn-dialog {
    margin-top: auto;
  }
}
</style>
