export const ressources = [
  // Tilesheets base
  { name: 'buildings', type: 'image', src: '/data/base/buildings-and-outside/buildings.png' },
  { name: 'floors-nature', type: 'image', src: '/data/base/buildings-and-outside/floors-nature.png' },
  { name: 'nature', type: 'image', src: '/data/base/buildings-and-outside/nature.png' },
  { name: 'crops', type: 'image', src: '/data/base/buildings-and-outside/crops.png' },
  { name: 'glitching', type: 'image', src: '/data/base/glitching.png' },
  { name: 'Craftables', type: 'image', src: '/data/base/objects-and-interior/Craftables.png' },
  { name: 'townInterior.fr-FR', type: 'image', src: '/data/base/objects-and-interior/townInterior.fr-FR.png' },
  { name: 'zinswiller', type: 'image', src: '/data/base/zinswiller.png' },

  // Tilesheets anim external
  { name: 'floors-nature', type: 'tsx', src: '/data/base/buildings-and-outside/floors-nature.json' },
  { name: 'glitching', type: 'tsx', src: '/data/base/glitching.json' },

  // Maps
  { name: 'forest', type: 'tmx', src: '/data/maps/forest.tmx' },
  { name: 'zinswiller', type: 'tmx', src: '/data/maps/zinswiller.tmx' },
  { name: 'val-ajol', type: 'tmx', src: '/data/maps/val-ajol.tmx' },
  { name: 'niederbronx', type: 'tmx', src: '/data/maps/niederbronx.tmx' },
  { name: 'rothbach', type: 'tmx', src: '/data/maps/rothbach.tmx' },
  { name: 'livouy', type: 'tmx', src: '/data/maps/livouy.tmx' },
  { name: 'tremonzey', type: 'tmx', src: '/data/maps/tremonzey.tmx' },
  { name: 'eckbo', type: 'tmx', src: '/data/maps/eckbo.tmx' },


  { name: 'bike', type: 'image', src: 'data/characters/bike.png' },
  // Characters
  { name: 'marjo', type: 'image', src: 'data/characters/marjo.png' },
  { name: 'maenele', type: 'image', src: 'data/characters/maenele.png' },
  { name: 'mc', type: 'image', src: 'data/characters/marjo.png' },
  { name: 'bernard', type: 'image', src: 'data/characters/bernard.png' },
  { name: 'bob', type: 'image', src: 'data/characters/bob.png' },
  { name: 'bret', type: 'image', src: 'data/characters/bret.png' },
  { name: 'juliette', type: 'image', src: 'data/characters/juliette-new.png' },
  { name: 'dolly', type: 'image', src: 'data/characters/dolly.png' },
  { name: 'huck', type: 'image', src: 'data/characters/huck.png' },
  { name: 'sylvie', type: 'image', src: 'data/characters/sylvie.png' },
  { name: 'eric', type: 'image', src: 'data/characters/eric.png' },
  { name: 'maite', type: 'image', src: 'data/characters/maite.png' },
  { name: 'martine', type: 'image', src: 'data/characters/martine.png' },
  { name: 'jean', type: 'image', src: 'data/characters/jean.png' },
  { name: 'gilles', type: 'image', src: 'data/characters/gilles.png' },
  { name: 'michele', type: 'image', src: 'data/characters/michele.png' },
  { name: 'jeanne', type: 'image', src: 'data/characters/jeanne.png' },
  { name: 'yvan', type: 'image', src: 'data/characters/yvan.png' },
  { name: 'youri', type: 'image', src: 'data/characters/youri.png' },
  { name: 'caroline', type: 'image', src: 'data/characters/caroline.png' },
  { name: 'manon', type: 'image', src: 'data/characters/manon.png' },
  { name: 'marion', type: 'image', src: 'data/characters/marion.png' },
  { name: 'saliou', type: 'image', src: 'data/characters/saliou.png' },
  { name: 'max', type: 'image', src: 'data/characters/max.png' },
  { name: 'nico', type: 'image', src: 'data/characters/nico.png' },

  // Misc
  { name: 'icons', type: 'image', src: 'data/symbols/icons.png' }
]
