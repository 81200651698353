<template>
  <div class="game-view wh-100">
    <div id="screen" class="wh-100" />

    <game-header />

    <chat-dialog
      v-if="action"
      v-bind="action"
      @finished="onTalkEnded"
    />

    <ask-dialog
      v-if="ask"
      v-bind="ask"
      @finished="onAskEnded"
    />

    <mission-dialog
      v-if="mission"
      v-bind="mission"
      @finished="onMissionAccepted"
    />

    <mailbox-dialog
      v-if="mailbox"
      @quit="mailbox = false"
    />

    <component
      v-if="dialog"
      :is="dialog.type" v-bind="dialog"
      @finished="onDialogEnd"
    />

    <component
      :is="special" v-bind="mission"
      @finished="onSpecialFinished"
    />
  </div>
</template>

<script>
/* global me */
import EventBus from '@/game/EventBus'


export default {
  name: 'Game',

  data () {
    return {
      debug: {},
      mailbox: false,
      action: null,
      ask: null,
      mission: null,
      special: null,
      dialog: null
    }
  },

  methods: {
    onGameLoaded () {
      EventBus.$on('talk-start', action => {
        this.$nextTick(() => {
          this.action = action
        })
      })

      EventBus.$on('ask-start', action => {
        this.$nextTick(() => {
          this.ask = action
        })
      })


      EventBus.$on('dialog-start', dialog => {
        this.$nextTick(() => {
          console.log(dialog)
          this.dialog = dialog
        })
      })

      // EventBus.$on('mailbox-opened', () => {
      //   this.mailbox = true
      // })
    },

    onTalkEnded () {
      const mission = this.action.mission
      this.action = null
      EventBus.$emit('talk-end')
      if (mission) {
        this.mission = mission
      }
    },

    onAskEnded (response) {
      EventBus.$emit('ask-end', response)
      this.ask = null
    },

    onMissionAccepted () {
      if (this.mission.type !== undefined && this.mission.status === 'available') {
        this.special = this.mission.type
      } else {
        EventBus.$emit('mission-end')
        this.mission = null
      }
    },

    onSpecialFinished () {
      this.special = null
      this.mission.status = 'done'
      EventBus.$emit('mission-end')
      this.mission = null
    },

    onDialogEnd () {
      EventBus.$emit('dialog-end')
      this.dialog = null
    }
  },

  async mounted () {
    await this.$store.dispatch('INIT_GAME')
    this.onGameLoaded()
  }
}
</script>

<style lang='scss' scoped>
</style>
