<template>
  <div class="dialog ask-dialog">
    <span class="pseudo">{{ pseudo }}</span>
    <div class="dialog-content ask-dialog-content" v-html="ask" />

    <ul>
      <li v-for="([key, value], i) in choices" :key="key" :class="{ selected : selected === i }">
        {{ value }}
      </li>
    </ul>

    <button
      @click="onButtonClick"
      type="button" name="next" class="btn-dialog"
    >
      Space
    </button>
  </div>
</template>

<script>
/* global me */
import { createPopper } from '@popperjs/core'


export default {
  name: 'AskDialog',

  props: {
    pseudo: { type: String, required: true },
    ask: { type: [Array, String], required: true },
    choices: { type: Array, required: true },
    pos: { type: Object, required: true }
  },

  data () {
    return {
      selected: 0
    }
  },

  methods: {
    getPos () {
      const x = this.pos.x + (5 * me.video.scaleRatio.x)
      const y = this.pos.y - (20 * me.video.scaleRatio.x)
      return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x
      })
    },

    onKeydown (e) {
      e.stopPropagation()
      if (e.code === 'KeyS') {
        if (this.selected + 1 < this.choices.length) {
          this.selected++
        }
      }
      if (e.code === 'KeyW') {
        if (this.selected - 1 > -1) {
          this.selected--
        }
      }
      if (e.code === 'Space') {
        this.onButtonClick()
      }
    },

    onButtonClick () {
      this.$emit('finished', this.selected)
    }
  },

  mounted () {
    const virtualElement = { getBoundingClientRect: this.getPos() }
    createPopper(virtualElement, this.$el, { placement: 'top-start' })
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.ask-dialog {
  max-width: 350px;
  min-width: 200px;
  border-bottom-left-radius: 0;

  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 0 10px;
      border-radius: 10px;
      &.selected {
        background-color: $color-2;
        color: white;
      }
    }
  }
}
</style>
