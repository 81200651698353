/* global me */
import EventBus from '@/game/EventBus'
import store from '@/store'
import FriendEntity from './FriendEntity'
import { sleep } from '@/game/utils'


export default FriendEntity.extend({
  init (x, y, settings) {
    this._super(FriendEntity, 'init', [x, y, settings])

    const info = store.getters.friendData(this.pseudo)
    this.givenPears = store.getters.givenPears
    this.beforeDelivery = info.beforeDelivery
    this.afterDelivery = info.afterDelivery
  },

  interact () {
    const action = {
      pseudo: this.humanName,
      pos: this.getScreenPos(),

      type: 'chat',
      content: this.chatChoices[this.givenPears.length < 4 ? this.givenPears.length : 4]
    }

    const newPears = store.getters.givablePears
    if (newPears.length) {
      action.content = this.beforeDelivery[this.givenPears.length < 4 ? this.givenPears.length : 4]
      EventBus.$once('talk-end', this.onPearsGiven.bind(this))
    } else {
      EventBus.$once('talk-end', this.onChatEnd.bind(this))
    }

    EventBus.$emit('talk-start', action)
  },

  async onPearsGiven () {
    const newPears = store.getters.givablePears
    const pears = me.game.world.getChildByName('PearIcon').filter(pear => newPears.includes(pear.pear))

    for (const pear of pears) {
      await sleep()
      pear.alpha = 1
    }
    store.dispatch('PEARS_GIVEN', { pears: newPears })
    this.givenPears = store.getters.givenPears

    if (!store.getters.eventDone('bike')) {
      store.dispatch('EVENT_DONE', { name: 'bike' })
      me.game.world.getChildByName('BikeEntity')[0].show()
    }

    const action = {
      pseudo: this.humanName,
      pos: this.getScreenPos(),

      type: 'chat',
      content: this.afterDelivery[this.givenPears.length < 4 ? this.givenPears.length : 4]
    }

    EventBus.$emit('talk-start', action)
  }
})
