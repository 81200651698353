<template>
  <div class="mailbox-dialog">
    <div class="mailbox-dialog-pin">Mailbox</div>
    <div class="dialog-content mailbox-dialog-content">
      <div v-if="currentMail === null" class="mailbox-list">
        <ul>
          <li v-for="({ title, read }, name) in mails" :key="title" @click="onMailClick(name)">
            {{ title }}
            <span v-if="!read" class="new">new !</span>
          </li>
        </ul>
      </div>
      <div
        v-if="currentMail" :key="currentMail.title"
        class="mail-view"
      >
        <p v-for="(line, i) in currentMail.content" :key="i" v-html="line" />
      </div>
      <button
        type="button" name="accept"
        class="btn-dialog"
        @click="onButtonClick"
      >
        {{ currentMail ? 'Revenir' : 'Fermer' }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'MailboxDialog',

  data () {
    return {
      currentMail: null
    }
  },

  computed: {
    ...mapGetters(['mails'])
  },

  methods: {
    onKeydown (e) {
      e.stopPropagation()
      console.log(e.code)
      if (['Space', 'Escape'].includes(e.code)) {
        this.onButtonClick()
      }
    },

    onMailClick (name) {
      this.$store.dispatch('SET_MAIL_READ', name)
      this.currentMail = this.mails[name]
    },

    onButtonClick () {
      if (this.currentMail === null) {
        this.$emit('quit')
      } else {
        this.currentMail = null
      }
    }
  },

  mounted () {
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
  }
}
</script>

<style lang="scss" scoped>
.mailbox-dialog {
  position: absolute;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;

  padding: 40px;

  &-content {
    padding: 90px 60px;
    border-radius: 90px;
    border-top-left-radius: 0;
    background-color: $color-1;
    color: $dark;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-pin {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-self: flex-start;

    background-color: $color-1;
    color: $dark;
  }

  .mailbox-list {
    ul {
      list-style: none;
      padding: 0;
    }
    li {
      padding: 5px 15px;
      border: 2px solid $dark;
      cursor: pointer;

      &:hover {
        background-color: $dark;
        color: $white;
      }

      &:not(:first-of-type) {
        border-top: 0;
      }

      &:first-of-type {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }

      &:last-of-type {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }

  .new {
    display: inline-block;
    padding: 0 8px 0 0;


    font-size: 1rem;
    line-height: 1;
    text-align: center;

    color: $white;
    text-shadow: 3px 3px $color-2;
    font-weight: 700;
    transform-origin: 5px 5px;
    margin-left: 10px;

    @keyframes slidein {
      from { transform:  translateY(-5px) rotate(7deg); }
      to   { transform:  translateY(3px) rotate(-12deg); }
    }

    animation: 250ms linear infinite alternate slidein;
  }

  .mail-view {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
